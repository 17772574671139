import { useSelector } from "react-redux";
import { SecondItemDescriptionCard } from "../../components/SecondItemDescriptionCard/SecondItemDescriptionCard";
import { ItemPrincipalDescriptionCard } from "../../components/ItemPrincipalDescriptionCard/ItemPrincipalDescriptionCard";
import FormCustom from "../../components/FormCustom/FormCustom";
import { MethodPayment } from "../../components/MethodPayment/MethodPayment";

import "./styles.css";

export const Reservation = () => {
  const itemRedux = useSelector((state) => state.itemProduct);
  // console.log("estoy en el RESERVATION con el item redux", itemRedux);
  const userCounter = useSelector((state) => state.passengers);

  return (
    <div className="container_Reservation">
      <h1>
        {itemRedux.typename == "ProgrammingPopulateResponse"
          ? "Tu mejor destino"
          : "Paquetes turísticos"}
      </h1>

      <div className="item_one_Reservation">
        <img
          src={`https://demo-files.tuviajeapp.co/test/${itemRedux.imagen}`}
          alt="imagen"
        />
        <div className="item_description_Reservation">
          <h2>{itemRedux.name}</h2>
          <ItemPrincipalDescriptionCard
            days={itemRedux.day}
            nights={itemRedux.night}
            acomodacion={itemRedux.acomodacion}
            ubication={itemRedux.destination}
            typeProgramg={itemRedux.typename}
            origen={itemRedux.origen}
          />
        </div>
      </div>

      <div className="item_two_Reservation">
        <SecondItemDescriptionCard
          typeProgramg={itemRedux.typename}
          activeReservation={false}
          counter={false}
        />
      </div>
      <div>
        <FormCustom start={itemRedux.origen} />
      </div>
      <MethodPayment />
    </div>
  );
};
